import React, { useState, useEffect } from "react";
import MyNavbar from "./common/nav";
import Table from "react-bootstrap/Table";
import UseModal from "./common/useModal";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import AddMembro from "./common/addMembro";
import EditMembro from "./common/editMembro";
import DelMembro from "./common/delMembro";
import { getMembro } from "./services/source";

function Membros() {
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editdata, setEditData] = useState({});
  const [deldata, setDelData] = useState({});
  const [del, setDel] = useState(false);
  

  const [dispMembro, setDispMembro] = useState([]);

  const handleadd = () => {
    setAdd(true);
  };

  const handleEdit = (edit) => {
    setEdit(true);
    setEditData(edit);
  };

  const handleDelete = (del) => {
    setDel(true);
    setDelData(del);
  };

  useEffect(() => {
    async function getRecordMembro() {
      const result = await getMembro();
      setDispMembro(result.data);
    }
    getRecordMembro();
  }, []);

  return (
    <div className="content">
      <MyNavbar />
      <div className="container-fluid">
      <div className="mt-3">
        <div className="col-md-8 col-xl-6 mx-auto">
          <div className="card">
            <div className="card-header">
              <h5 className="text-center mb-0" style={{ fontWeight: "bold" }}>
                Membros
              </h5>
            </div>
            <label htmlFor="" className="mt-1 text-center">
              <p style={{ fontSize: "italic" }}>Igreja do Nazereno de Almada</p>
            </label>
            <button
              className="btn btn-outline-success"
              onClick={() => handleadd()}
            >
              <i className="bi bi-plus-circle"> </i>Adicionar Membros
            </button>
            <UseModal
              size={"lg"}
              show={add}
              close={() => setAdd(false)}
              body={<AddMembro />}
              title="Adicionar Membro"
            />
            <div className="card-body">
              <div className="mb-3">
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Foto</th>
                      <th>Numero</th>
                      <th>Nome</th>
                      <th>Telemovel</th>
                      <th>Opcoes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dispMembro.map((m, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {m.file && (
                              <img
                                src={
                                  process.env.REACT_APP_API_URL + "/" + m.file
                                }
                                alt="Foto"
                                width="50"
                                height="50"
                              />
                            )}
                          </td>
                          <td>{m.numero}</td>
                          <td>{m.nome}</td>
                          <td>{m.telemovel}</td>
                          <td>
                            <DropdownButton
                              id="dropdown-basic-button"
                              title="Opções"
                              variant="secundary"
                            >
                              {" "}
                              <Dropdown.Item onClick={() => handleEdit(m)}>
                                <i className="fa fa-edit">Editar</i>
                              </Dropdown.Item>
                              <UseModal
                                size={"lg"}
                                show={edit}
                                close={() => setEdit(false)}
                                body={<EditMembro {...editdata} />}
                                title="Editar Membro"
                              />
                              <Dropdown.Item onClick={() => handleDelete(m)}>
                                <i className="fa fa-trash">Apagar</i>
                              </Dropdown.Item>
                              <UseModal
                                show={del}
                                close={() => setDel(false)}
                                body={<DelMembro {...deldata} />}
                                title="Apagar Membro"
                              />
                            </DropdownButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Membros;
