import React, { useState } from "react";
import Input from "./input";
import { useForm } from "react-hook-form";
import "../../styles/membros.css";
import { addMenbro } from "../services/source";
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";

function AddMembro() {
  //   const schema = yup.object().shape({});

  const { register, handleSubmit } = useForm();

  const [image, setImage] = useState({ preview: "", raw: "" });

  const handleFileChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('file', data.file[0]);
    formData.append('name', data.name);
    formData.append('numero', data.numero);
    formData.append('telemovel', data.telemovel);
    formData.append('voto', data.voto);
    try {
      await addMenbro(formData);
      window.location.reload();
    }catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Foto */}
      <div className="row mb-3">
        <h5 className="text-center">Carregar Foto</h5>
        <div className="col-md-12">
          <div className="form-floating mb-3 mb-md-0">
            <div className="text-center">
              {image.preview ? (
                <img src={image.preview} alt="dummy" width="150" height="150"/>
              ) : (
                <>
                  <span className="fa-stack fa-2x mt-3 mb-2">
                    <i className="fa fa-user fa-1x" />
                    <i className="fa fa-circle-thin fa-stack-2x " />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <label className="custom-file-input" {...register("file")}>
        <input style={{"display":"none"}} className="custom-file-input" type="file" onChange={handleFileChange} name="file"  />
        </label>
      </div>
      {/* #################################### */}
      {/* Nome */}
      <div className="row mb-3">
        <div className="col-md-12">
          <div className="form-floating mb-3 mb-md-0">
            <Input
              className="form-control"
              id="nome"
              type="text"
              name="name"
              register={register}
              label="Nome"
              placeholder="Nome"
            />
          </div>
        </div>
      </div>
      {/* Numero  & Telefone */}
      <div className="row mb-3">
        <div className="col-md-6">
          <div className="form-floating mb-3 mb-md-0">
            <Input
              className="form-control"
              id="numero"
              type="text"
              name="numero"
              register={register}
              label="Numero"
              placeholder="Numero"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3 mb-md-0">
            <Input
              className="form-control"
              id="telemovel"
              type="text"
              name="telemovel"
              register={register}
              label="Telemovel"
              placeholder="Telemovel"
            />
          </div>
        </div>
        <input type="hidden" value="0" {...register("voto")} />
      </div>
      {/* #################################### */}
      {/* Button */}
      <div className="mt-4 mb-0">
        <div className="d-grid">
          <button type="submit" className="btn btn-success">
            Adicionar Membro
          </button>
        </div>
      </div>
    </form>
  );
}

export default AddMembro;
