import React from "react";
import { deleteMembro } from "../services/source";

function DelMembro(data) {
  const handleDelete = async () => {
    await deleteMembro(data)
    .then((res) =>
      res ? window.location.reload(true) : null
    );
  };

  return (
    <>
      <p>
        Tem a certeza que deseja apagar permanentemente o registo de
        <strong> {data.nome}</strong>!!!!
      </p>

      <button className="btn btn-danger" type="submit" onClick={handleDelete}>
        Apagar
      </button>
    </>
  );
}

export default DelMembro;
