import React, { useState,useEffect } from "react";
import Input from "./input";
import { useForm } from "react-hook-form";
import { editMembro } from "../services/source";
import Form from "react-bootstrap/Form";


function EditMembro(data) {
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [votos, setVotos] = useState();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: data.nome,
      numero: data.numero,
      telemovel: data.telemovel,
      id: data.id,
    },
  });

  
  useEffect(()=>{
    let voto = data.voto;
    if(voto === "0"){setVotos(false)}else{setVotos(true)};
  },[data.voto])


  const onSubmit = async (data, e) => {
    e.preventDefault();
   let formData = new FormData();
    formData.append('name', data.name);
    formData.append('numero', data.numero);
    formData.append('telemovel', data.telemovel);
    formData.append('id', data.id);
    formData.append('voto', data.voto);
    if(data.file[0]){
      formData.append('file', data.file[0]);
    }
    try {
      await editMembro(formData);
      window.location.reload();
    }catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      {/* Foto */}
      <div className="row mb-3">
        <h5 className="text-center">Carregar Foto</h5>
        <div className="col-md-12">
          <div className="form-floating mb-3 mb-md-0">
            <div className="text-center">
              {image.preview ? (
                <img src={image.preview} alt="dummy" width="150" height="150" />
              ) : (
                <>
                  <img
                    src={process.env.REACT_APP_API_URL + "/" + data.file}
                    alt="Foto"
                    width="150"
                    height="150"
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <label className="custom-file-input" {...register("file")}>
          <input
            style={{ display: "none" }}
            className="custom-file-input"
            type="file"
            onChange={handleFileChange}
            name="file"
          />
        </label>
      </div>
      {/* Nome */}
      <div className="row mb-3">
        <Input
          className="form-control"
          type="hidden"
          name="id"
          register={register}
        />
        <div className="col-md-12">
          <div className="form-floating mb-3 mb-md-0">
            <Input
              className="form-control"
              id="nome"
              type="text"
              name="name"
              register={register}
              label="Nome"
              placeholder="Nome"
            />
          </div>
        </div>
      </div>
      {/* Numero  & Telefone */}
      <div className="row mb-3">
        <div className="col-md-6">
          <div className="form-floating mb-3 mb-md-0">
            <Input
              className="form-control"
              id="numero"
              type="text"
              name="numero"
              register={register}
              label="Numero"
              placeholder="Numero"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3 mb-md-0">
            <Input
              className="form-control"
              id="telemovel"
              type="text"
              name="telemovel"
              register={register}
              label="Telemovel"
              placeholder="Telemovel"
            />
          </div>
        </div>
        <div className="col-md-6 mt-3">
            <div className="form-floating mb-3 mb-md-0">
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Votou"
                {...register("voto")}
                defaultChecked={votos}
              />
            </div>
          </div>
      </div>
      {/* #################################### */}
      {/* Button */}
      <div className="mt-4 mb-0">
        <div className="d-grid">
          <button type="submit" className="btn btn-success">
            Editar Membro
          </button>
        </div>
      </div>
    </form>
  );
}

export default EditMembro;
