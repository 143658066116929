import React, { useState, useEffect } from "react";
import Navbar from "./common/nav";
import {
  getSelectDepartamentos,
  getCard,
  addManVotos,
} from "./services/source";
import { Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import UseModal from "./common/useModal";
import "../styles/addVotos.css";

function AddVotos() {
  const [department, setDeparment] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [showError, setShowError] = useState(false);
  const [quantities, setQuantities] = useState({});

  useEffect(() => {
    async function getListDepatment() {
      const result = await getSelectDepartamentos();
      setDeparment(result.data);
    }
    getListDepatment();
  }, []);

  useEffect(() => {
    async function makeCard() {
      const result = await getCard();
      setCandidates(result.data);
    }
    makeCard();
  }, []);

  const handleQuantityChange = (department, candidate, change) => {
    const key = `${department}_${candidate}`;
    setQuantities((prevQuantities) => {
      const newQuantity = (prevQuantities[key] || 0) + change;
      return {
        ...prevQuantities,
        [key]: Math.max(newQuantity, 0), // Ensure the quantity is not negative
      };
    });
  };

  const mappedObjects = department.map((firstItem) => {
    const matchingObjects = candidates.filter(
      (secondItem) => secondItem.id_departamento === firstItem.id_departamento
    );
    return { ...firstItem, objects: matchingObjects };
  });

  const { handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
    try {
      const formattedVotes = Object.entries(quantities).map(([key, value]) => ({
        departamento: key.split("_")[0],
        voto: key.split("_")[1],
        quantity: value,
      }));
      await addManVotos(formattedVotes).then((res) =>
        res ? setShowResult(true) : setShowError(true)
      );
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const handleback = () => {
    setQuantities({});
  };

  return (
    <div className="content">
      <Navbar />
      <div className="container-fluid">
        <div className="mt-3">
          <div className="col-md-8 col-xl-6 mx-auto">
            <form onSubmit={handleSubmit(onSubmit)}>
              {mappedObjects.map((v, index) => {
                return (
                  <div className="card" key={index}>
                    <div className="card-header">
                      <h5
                        className="text-center mb-0"
                        style={{ fontWeight: "bold" }}
                      >
                        {v.departamento}
                      </h5>
                    </div>
                    <div className="card-body">
                      {v.objects.map((k) => {
                        return (
                          <div key={k.id_candidatos}>
                            <UseModal
                              size={"lg"}
                              show={showResult}
                              close={() => {
                                setShowResult(false);
                                window.location.reload();
                              }}
                              body="Voto Submetido com Sucesso"
                              title="Resultado Voto"
                            />
                            <UseModal
                              size={"lg"}
                              show={showError}
                              close={() => {
                                setShowError(false);
                              }}
                              style={{ backgroundColor: "red" }}
                              body="Ocurreu um erro o voto não foi submetido tente novamente..."
                              title="Resultado Voto"
                            />
                            <Table>
                              <tbody>
                                <tr>
                                  <td className="justify-content-start col-1">
                                    <img
                                      src={
                                        process.env.REACT_APP_API_URL +
                                        "/" +
                                        k.file
                                      }
                                      alt=""
                                      width="70px"
                                      height="70px"
                                      className="avatar"
                                    />
                                  </td>
                                  <td className="justify-content-start col-2 pt-3">
                                    <span className="names">{k.nome}</span>
                                  </td>
                                  <td className="text-center col-lg-1 col-sm-4  pt-4">
                                    <input
                                      type="button"
                                      value="-"
                                      className="button-minus border rounded-circle icon-shape icon-sm mx-1"
                                      data-field="quantity"
                                      onClick={() =>
                                        handleQuantityChange(
                                          v.departamento,
                                          k.candidatos,
                                          -1
                                        )
                                      }
                                    />
                                    <input
                                      type="number"
                                      step="1"
                                      max="100"
                                      value={
                                        quantities[
                                          `${v.departamento}_${k.candidatos}`
                                        ] || 0
                                      }
                                      name="quantity"
                                      className="quantity-field border-0 text-center w-25"
                                      onChange={(e) =>
                                        handleQuantityChange(
                                          v.departamento,
                                          k.candidatos,
                                          parseInt(e.target.value)
                                        )
                                      }
                                    />
                                    <input
                                      type="button"
                                      value="+"
                                      className="button-plus border rounded-circle icon-shape icon-sm"
                                      data-field="quantity"
                                      onClick={() =>
                                        handleQuantityChange(
                                          v.departamento,
                                          k.candidatos,
                                          1
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button className="btn btn-success mt-3 mb-3">
                  <i className="bi bi-shift"> </i>
                  Votar
                </button>
                <button
                  type="reset"
                  className=" btn btn-danger mt-3 mb-3 justify-content-end"
                  onClick={handleback}
                >
                  <i className="bi bi-trash"> </i>
                  Apagar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddVotos;
