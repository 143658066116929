import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getMembro, getVersiculo } from "./services/source";
import Badge from "react-bootstrap/Badge";
import Alert from "react-bootstrap/Alert";
import { getPublicar } from "./services/source";
import "../styles/home.css";

function Home() {
  let navigate = useNavigate();

  const schema = yup.object().shape({
    numero: yup
      .number()
      .required("Campo Obrigatório")
      .typeError("Numero Obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [membro, setMembro] = useState();
  const [showName, setShowName] = useState();
  const [showButton, setShowButton] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showVoto, setShowVoto] = useState(false);
  const [online, setOnline] = useState(false);
  const [ver, setVer] = useState();
  const [dataLoad, setDataLoad] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function getOnline() {
      const result = await getPublicar();
      setOnline(result.data);
    }
    getOnline();
  }, []);

  useEffect(() => {
    async function getVer() {
      try {
        const result = await getVersiculo();
        setVer(result.data);
        setDataLoad(true);
      } catch (error) {
        setError(error);
      }
    }
    getVer();
  }, []);
  
  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  const handleClick = () => {
    //Verificar se o numero é do administrador
    let administrator = membro[0].telemovel;
    if (administrator === "964785228") {
      let path = "dashboard";
      navigate(path);
    } else if (online === true) {
      if (membro[0].voto !== "0") {
        setShowVoto(true);
      } else {
        let path = "votos";
        navigate(path);
      }
    }
  };

  const onSubmit = async (data) => {
    const result = await getMembro();
    const mem = result.data;
    let typenumber = JSON.stringify(data.numero);
    let obj = mem.filter((e) => {
      return e.telemovel === typenumber;
    });
    if (obj.length > 0) {
      setMembro(obj);
      setShowName(obj[0].nome);
      obj ? setShowButton(true) : setShowButton(false);
      sessionStorage.setItem("membro", obj[0].telemovel);
    } else {
      setShowAlert(true);
    }
  };

  return (
    <div>
      <main className="container-fluid">
        <div className="row mb-3 mt-5">
          <div className="col-12">
            <img
              src={process.env.PUBLIC_URL + "/image/logo.png"}
              alt="INA"
              className="img-fluid mx-auto d-block"
              style={{ maxWidth: "40%" }}
            />
          </div>
        </div>

        {showAlert ? (
          <Alert
            className="text-center"
            variant="danger"
            dismissible
            onClose={() => {
              setShowAlert(false);
              window.location.reload();
              sessionStorage.removeItem("membro");
            }}
          >
            <strong> Numero Nāo Encontrado 😭😭!!!!</strong>
          </Alert>
        ) : null}
        {showVoto ? (
          <Alert
            className="text-center"
            variant="danger"
            dismissible
            onClose={() => {
              setShowVoto(false);
              window.location.reload();
              sessionStorage.removeItem("membro");
            }}
          >
            <strong> O Voto ja descarregado 😭😭!!!!</strong>
          </Alert>
        ) : null}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="d-grid gap-2 col-5 mx-auto">
              <Badge bg="danger">{errors.numero?.message}</Badge>
              <div className="input-group  mb-md-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nº Telemovel"
                  style={{ textAlign: "center" }}
                  {...register("numero")}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="d-grid gap-2 mb-1 col-4 mx-auto">
                <span className="badge bg-info text-dark">{showName}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="d-grid gap-2 col-5 mx-auto">
              {!showButton ? (
                <button className="btn btn-success">Encontrar</button>
              ) : null}
              {showButton ? (
                <button className="btn btn-danger" onClick={handleClick}>
                  Votar
                </button>
              ) : null}
            </div>
          </div>
        </form>

        {dataLoad && ver.length > 0 && (
          <div className="row mt-5">
            <div className="d-grid gap-2 col-lg-5 col-sm-1 mx-auto">
              <blockquote className="blockquote blockquote-custom mb-5  p-5 shadow rounded">
                <cite className="blockquote-footer" title="Source Title">
                  Versiculo do Mes
                </cite>

                <p className="mb-0 versiculo mt-2 font-italic text-center">
                  {ver[0].versiculo}
                </p>
                <p className="blockquote-footer pt-4 mt-2 border-top">
                  {ver[0].livro}
                </p>
              </blockquote>
            </div>
          </div>
        )}
      </main>
      <footer
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          textAlign: "center",
          color: "black",
          fontWeight: "bold",
          backgroundColor: "white",
          padding: "0",
        }}
      >
        <small> Copyright © 2023 idealabangola.com 🇦🇴</small>{" "}
      </footer>
    </div>
  );
}

export default Home;
