import React, { useState } from "react";
import Navbar from "./common/nav";
import BoletinsCriados from "./common/boletinscriados";
import UseModal from "./common/useModal";
import OneVote from "./common/oneVotos";
import MultVotos from "./common/mutVotos";

function Boletim() {
  const [one, setOne] = useState(false);
  const [mul, setMul] = useState(false);

  const handleShow = () => {
    setOne(true);
  };

  const handleMulVotes = () => {
   setMul(true);
  };


  return (
    <div className="content">
      <Navbar />
      <div className="container-fluid">
        <div className="mt-3">
          <div className="col-md-8 col-xl-6 mx-auto">
            <div className="card">
              <div className="card-header">
                <h5 className="text-center mb-0" style={{ fontWeight: "bold" }}>
                  Criar Boletim de Voto
                </h5>
              </div>
              <label htmlFor="" className="mt-1 text-center">
                <p style={{ fontSize: "italic" }}>
                  Igreja do Nazereno de Almada
                </p>
              </label>

              <div className="row justify-content-center mb-3">
                <div className="col-6 text-center">
                  <button
                    className="btn btn-outline-success"
                    onClick={handleShow}
                  >
                    <i className="bi bi-plus-circle"></i> Criar Boletim de Votos
                    <p>
                      <small>(um voto)</small>
                    </p>
                  </button>
                </div>
                <div className="col-6 text-center">
                  <button
                    className="btn btn-outline-primary"
                    onClick={handleMulVotes}
                  >
                    <i className="bi bi-plus-circle"></i> Criar Boletim de Votos
                    <p>
                      <small>(varios votos)</small>
                    </p>
                  </button>
                </div>
              </div>

              <UseModal
                size={"lg"}
                show={one}
                close={() => setOne(false)}
                body={<OneVote/>}
                title="Boletim de Voto"
              />

              <UseModal
                size={"lg"}
                show={mul}
                close={() => setMul(false)}
                body={<MultVotos/>}
                title="Boletim de Voto"
              />

            </div>
          </div>
        </div>
      </div>
      <BoletinsCriados />
    </div>
  );
}

export default Boletim;
