import React from "react";
import {deleteVotos} from "../services/source";

function DelVotos() {
  const handleDelete = async () => {
    await deleteVotos().then((res) =>
      res ? window.location.reload(true) : null
    );
  };

  return (
    <>
      <p>
        Tem a certeza que deseja apagar permanentemente apagar todos os votos.
      </p>

      <button className="btn btn-danger" type="submit" onClick={handleDelete}>
        Apagar
      </button>
    </>
  );
}

export default DelVotos;
