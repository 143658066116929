import React, { useEffect, useState } from "react";
import { getSelectDepartamentos, getCard } from "./services/source";
import "../styles/votingCard.css";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { votos, setVoto } from "./services/source";
import UseModal from "./common/useModal";

function Votos() {
  const [candidates, setCandidates] = useState([]);
  const [department, setDeparment] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [showError, setShowError] = useState(false);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

  useEffect(() => {
    async function getListDepatment() {
      const result = await getSelectDepartamentos();
      setDeparment(result.data);
    }
    getListDepatment();
  }, []);

  useEffect(() => {
    async function makeCard() {
      const result = await getCard();
      setCandidates(result.data);
    }
    makeCard();
  }, []);

  let navigate = useNavigate();

  const { register, handleSubmit, reset } = useForm();

  const handleback = () => {
    reset();
    let path = "/";
    navigate(path);
  };

  let tel = sessionStorage.getItem("membro");

  const onSubmit = async (data, e) => {
    e.preventDefault();
    await votos(data).then((res) => {
      if (res) {
        setShowResult(true);
        setVoto({ tel });
      } else {
        setShowError(true);
      }
    });
  };

  const mappedObjects = department.map((firstItem) => {
    const matchingObjects = candidates.filter(
      (secondItem) => secondItem.id_departamento === firstItem.id_departamento
    );
    return { ...firstItem, objects: matchingObjects };
  });

  return (
    <div className="content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid">
          <div className="mt-3">
            <div className="col-md-8 col-xl-6 mx-auto">
              {mappedObjects.map((v, index) => {
                const cardSelectedCandidateId = selectedCandidates[index];
                const allow = parseInt(v.numbvotos);
                const isSingleVote = allow === 1;

                // Calculate the number of selected checkboxes for this department
                const numSelectedCheckboxes = Object.values(selectedCheckboxes[index] || {}).filter(Boolean).length;
                return (
                  <div className="card" key={index}>
                    <div className="card-header">
                      <h5
                        className="text-center mb-0"
                        style={{ fontWeight: "bold" }}
                      >
                        {v.departamento}
                      </h5>
                      <div className="text-center mb-3">
                      <small>{v.notas}</small>
                      </div>
                    </div>
                    <div className="card-body">
                      {v.objects.map((k) => {
                       const isCheckboxChecked = selectedCheckboxes[index]?.[k.id_candidatos] || false; // Initialize with false
                        return (
                          <div key={k.id_candidatos}>
                            <UseModal
                              size={"lg"}
                              show={showResult}
                              close={() => {
                                setShowResult(false);
                                window.location.href = "/";
                              }}
                              body="Voto Submetido com Sucesso"
                              title="Resultado Voto"
                            />
                            <UseModal
                              size={"lg"}
                              show={showError}
                              close={() => {
                                setShowError(false);
                              }}
                              style={{ backgroundColor: "red" }}
                              body="Ocurreu um erro o voto não foi submetido tente novamente..."
                              title="Resultado Voto"
                            />
                            <Table>
                              <tbody>
                                <tr>
                                  <td className="justify-content-start col-1">
                                    <img
                                      src={
                                        process.env.REACT_APP_API_URL +
                                        "/" +
                                        k.file
                                      }
                                      alt=""
                                      width="70px"
                                      height="70px"
                                      className="avatar"
                                    />
                                  </td>
                                  <td className="justify-content-start col-2 pt-3">
                                    <span className="names">{k.nome}</span>
                                  </td>
                                  <td className="text-center col-1 pt-4">
                                    {isSingleVote ? (
                                      <input
                                        {...register(`${v.departamento}`, {
                                          shouldUnregister: true,
                                        })}
                                        name={`${v.departamento}`}
                                        type="radio"
                                        value={k.candidatos}
                                        checked={
                                          cardSelectedCandidateId ===
                                          k.id_candidatos
                                        }
                                        onChange={() =>
                                          setSelectedCandidates(
                                            (prevSelectedCandidates) => ({
                                              ...prevSelectedCandidates,
                                              [index]: k.id_candidatos,
                                            })
                                          )
                                        }
                                        style={{
                                          width: "30px",
                                          height: "33px",
                                        }}
                                      />
                                    ) : (
                                      <input
                                      {...register(
                                        `${v.departamento}`,
                                        {
                                          shouldUnregister: true,
                                        }
                                      )}
                                      name={`${v.departamento}`}
                                      type="checkbox"
                                      value={k.candidatos}
                                      checked={isCheckboxChecked}
                                      style={{
                                        width: "30px",
                                        height: "33px",
                                      }}
                                      onChange={(e) => {
                                        const updatedCheckboxes = { ...selectedCheckboxes };
                                        updatedCheckboxes[index] = updatedCheckboxes[index] || {};
                                        // Check if the limit of 2 checkboxes is reached
                                        if (allow && numSelectedCheckboxes >= allow && !isCheckboxChecked) {
                                          e.preventDefault(); // Prevent checking more than 2
                                        } else {
                                          updatedCheckboxes[index][k.id_candidatos] = e.target.checked;
                                          setSelectedCheckboxes(updatedCheckboxes);
                                        }
                                      }}
                                    />
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button className="btn btn-success mt-3 mb-3">
                  <i className="bi bi-shift"> </i>
                  Votar
                </button>
                <button
                  className=" btn btn-danger mt-3 mb-3 justify-content-end"
                  onClick={handleback}
                >
                  <i className="bi bi-arrow-left-circle"> </i>
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Votos;
