import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { getCandidatos, createBoletimVoto } from "../services/source";
import Input from "./input";

function OneVote() {
  const { register, handleSubmit } = useForm();
  const [formValues, setFormValues] = useState([{ candidato: "" }]);
  const [bestCandidatos, setBestCandidatos] = useState([]);

  useEffect(() => {
    async function makeCandidatos() {
      const result = await getCandidatos();
      setBestCandidatos(result.data);
    }
    makeCandidatos();
  }, []);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { candidato: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = formValues.filter((_, index) => index !== i);
    setFormValues(newFormValues);
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const payload = {
      formValues,
      data,
    };
    await createBoletimVoto(payload).then((res) =>
      res ? window.location.reload() : null
    );
    // setShowCard(false);
    setFormValues([{ candidato: "" }]);
  };

  return (
    <>
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="row mb-3">
              <div className="col-md-12">
                <div className="form-floating mb-3 mb-md-0">
                  <Input
                    className="form-control"
                    id="departamento"
                    type="text"
                    name="departamento"
                    register={register}
                    label="Departamento"
                    placeholder="Departamento"
                  />
                </div>
                <input type="hidden" value="0" {...register("activar")} />
                <input type="hidden" value="1" {...register("numbvotos")} />
                
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12">
                {formValues.map((element, index) => (
                  <div key={index}>
                    <div className="input-group mb-3">
                      {!index ? (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => addFormFields()}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </button>
                      ) : null}
                      {index ? (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => removeFormFields(index)}
                        >
                          <i className="fa fa-minus" aria-hidden="true"></i>
                        </button>
                      ) : null}
                      <span style={{ marginLeft: "2%" }}></span>
                      <select
                        name="candidato"
                        className="form-control"
                        onChange={(e) => handleChange(index, e)}
                        value={element.candidato || ""}
                      >
                        <option value="">Selecionar Cadidatos</option>
                        {bestCandidatos.map((option, index) => (
                          <option key={index} value={option.id}>
                            {option.nome}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ))}
              </div>
          
                <button className="btn btn-success mt-3 mb-3">
                  <i className="bi bi-card-checklist"> </i>
                  Criar
                </button>
              
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default OneVote;
