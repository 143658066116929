import React, { useEffect, useState } from "react";
import "../../styles/votingCard.css";
import { displayBoletim } from "../services/source";
import { Table } from "react-bootstrap";

function VotingCards(data) {
  const [boletim, setBoletim] = useState([]);

  useEffect(() => {
    async function getInfoBoletim() {
      const result = await displayBoletim(data);
      if (result) {
        setBoletim(result.data);
      }
    }
    getInfoBoletim();
  }, [data]);

  return (
    <div className="mt-3">
      <div className="card">
        <div className="card-header">
          <h5 className="text-center">{boletim && boletim[0]?.departamento}</h5>
        </div>
        <div className="card-body">
          {boletim.length > 0
            ? boletim &&
              boletim.map((v, index) => {
                return (
                  <div key={index}>
                    <Table  >
                      <tbody>
                      <tr>
                        <td className="justify-content-start col-1 ">
                          <img
                            src={process.env.REACT_APP_API_URL + "/" + v.file}
                            alt=""
                            width="70px"
                            height="70px"
                            className="avatar"
                          />
                        </td>
                        <td className="justify-content-start col-2 pt-3">
                          <span className="names">{v.nome}</span>
                        </td>
                        {/* <td className="text-center col-1 pt-4">
                          <input
                            name="1"
                            type="radio"
                            style={{
                              width: "30px",
                              height: "33px",
                            }}
                          />
                        </td> */}
                      </tr>
                      </tbody>
                    </Table>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}

export default VotingCards;
