import React from "react";
import { Routes, Route } from "react-router-dom";

import Layout from "./components/layout";
import Home from "./components/home";
import Dashboard from "./components/dashboard";
import Membros from "./components/membros";
import Boletim from "./components/boletim";
import Logout from './components/logout';
import Votos from "./components/votos";
import AddVotos from "./components/addVotos";
import Resultados from "./components/resultados";


function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route exact path="/" element={<Home />} />
          <Route>
            <Route path="votos" element={<Votos/>}/>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="membros" element={<Membros/> }/>
            <Route path="boletim" element={<Boletim />}/>
            <Route path="addVotos" element={<AddVotos />}/>
            <Route path="resultados" element={<Resultados />}/>
            <Route path="logout" element={<Logout />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
