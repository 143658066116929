import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import UseModal from "./useModal";
import VotingCards from "./votingCards";
import DelBoletim from "./delBoletim";
import { getDepartamentos, setBoletim } from "../services/source";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";

function BoletinsCriados() {
  useEffect(() => {
    async function listDepartamentos() {
      const result = await getDepartamentos();
      setListDepartamentos(result.data);
    }
    listDepartamentos();
  }, []);

  const [listDepartamentos, setListDepartamentos] = useState([]);
  const [del, setDel] = useState(false);
  const [ver, setVer] = useState(false);
  const [deldata, setDelData] = useState({});
  const [verdata, setVerData] = useState({});
  const [votos, setVotos] = useState({});

  useEffect(() => {
    // Initialize the 'votos' state with an object containing department IDs as keys and boolean values
    const initialVotosState = {};
    listDepartamentos.forEach((d) => {
      initialVotosState[d.id_departamento] = d.activar === "1";
    });
    setVotos(initialVotosState);
  }, [listDepartamentos]);

  const { register, handleSubmit } = useForm({});

  const handleDelete = (del) => {
    setDel(true);
    setDelData(del);
  };
  const handleVisualizacao = (ver) => {
    setVer(true);
    setVerData(ver);
  };

  const handleToggle = (id_departamento) => {
    // Update the 'votos' state by toggling the value for the specific department
    setVotos((prevVotos) => ({
      ...prevVotos,
      [id_departamento]: !prevVotos[id_departamento],
    }));
    setBoletim(id_departamento);
  };

  console.log()

  return (
    <>
      {/* Boletim de Votos Criados */}
      <div className="container-fluid">
        <div className="mt-3">
          <div className="col-md-8 col-xl-6 mx-auto">
            <div className="card">
              <div className="card-header">
                <h5 className="text-center mb-0" style={{ fontWeight: "bold" }}>
                  Boletim de Votos Criados
                </h5>
              </div>
              <form onSubmit={handleSubmit(handleToggle)}>
                <div className="card-body">
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Departamento</th>
                        <th>Opções</th>
                        <th className="text-center">Boletim</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listDepartamentos.map((d) => (
                        <tr key={d.id_departamento}>
                          <td>{d.departamento}</td>
                          <td>
                            <DropdownButton
                              id="dropdown-basic-button"
                              title="Opções"
                              variant="secundary"
                            >
                              <Dropdown.Item
                                onClick={() => handleVisualizacao(d)}
                              >
                                <i className="fa fa-eye">Visualizar</i>
                              </Dropdown.Item>
                              <UseModal
                                size={"lg"}
                                show={ver}
                                close={() => setVer(false)}
                                body={<VotingCards {...verdata} />}
                                title="Boletim de Voto"
                              />

                              <Dropdown.Item onClick={() => handleDelete(d)}>
                                <i className="fa fa-trash">Apagar</i>
                              </Dropdown.Item>
                              <UseModal
                                show={del}
                                close={() => setDel(false)}
                                body={<DelBoletim {...deldata} />}
                                title="Apagar Membro"
                              />
                            </DropdownButton>
                          </td>
                          <td className="text-center">
                            <Form.Check
                              type="switch"
                              id={d.id_departamento}
                              {...register("voto")}
                              checked={votos[d.id_departamento] || false}
                              onChange={() => handleToggle(d.id_departamento)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BoletinsCriados;
