import React from "react";
import { NavLink } from "react-router-dom";

function MyNavbar() {
  return (
    
      <main
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          backgroundColor: "rgb(134 158 162)",
        }}
      >
        <nav className="  navbar navbar-light navbar-expand-md">
          <div className="container-fluid">
            <img
              src={process.env.PUBLIC_URL + "image/logo.png"}
              alt="logo"
              className="img-fluid"
              style={{ maxWidth: "7%", margin: "auto", paddingRight:"40px" }}
            />
            <button
              className="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#navcol-1"
            >
              <span className="visually-hidden">Toggle navigation</span>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div id="navcol-1" className="collapse navbar-collapse">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/dashboard">
                    <div
                      className="sb-nav-link-icon"
                      style={{ color: "white" }}
                    >
                      <i className="bi bi-house"></i> Pagina Principal
                    </div>
                  </NavLink>
                </li>
            
                <li className="nav-item">
                  <NavLink className="nav-link" to="/membros">
                    <div
                      className="sb-nav-link-icon"
                      style={{ color: "white" }}
                    >
                      <i className="bi bi-people"></i> Membros
                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/boletim">
                    <div
                      className="sb-nav-link-icon"
                      style={{ color: "white" }}
                    >
                      <i className="bi bi-card-checklist"> </i> Boletim Voto                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/addVotos">
                    <div
                      className="sb-nav-link-icon"
                      style={{ color: "white" }}
                    >
                      <i className="bi bi-plus-circle-fill"> </i> Adicionar Voto                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/resultados">
                    <div
                      className="sb-nav-link-icon"
                      style={{ color: "white" }}
                    >
                      <i className="bi bi-pie-chart-fill"> </i>Resultados
                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/logout">
                    <div
                      className="sb-nav-link-icon"
                      style={{ color: "white" }}
                    >
                      <i className="bi bi-box-arrow-right"> </i>Sair
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </main>
    
  );
}

export default MyNavbar;
