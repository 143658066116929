import React, { useEffect, useState } from "react";
import Navbar from "./common/nav";
import "../styles/dashboard.css";
import "../styles/background.css";
import Input from "./common/input";
import { useForm } from "react-hook-form";
import { updateVersiculo, countMembros, countVotos } from "./services/source";
import UseModal from "./common/useModal";
import DelVotos from "./common/delVotos";

function Daschboard() {
  const { register, handleSubmit } = useForm();

  const [countMembro, setCountMembro] = useState();
  const [membro, setMembro] = useState(false);
  const [countVoto, setCountVoto] = useState();
  const [votos, setVotos] = useState(false);
  const [del, setDel] = useState(false);


  useEffect(() => {
    async function CountMem() {
      const result = await countMembros();
      setCountMembro(result.data);
      setMembro(true);
    }
    CountMem();
  }, []);

  useEffect(() => {
    async function CountVot() {
      const result = await countVotos();
      setCountVoto(result.data);
      setVotos(true);
    }
    CountVot();
  }, []);

  const onSubmit = async (data) => {
    try {
      await updateVersiculo(data);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = () => {
    setDel(true);
  };

  return (
    <div className="content">
      <Navbar />
      <div className="text-center" style={{ color: "white" }}>
        <h1>Pagina Principal</h1>
      </div>
      <UseModal
        show={del}
        close={() => setDel(false)}
        body={<DelVotos />}
        title="Apagar Votos"
      />
      <div className="container-fluid">
        <div className="row">
          {/* Numero Membros */}
          {membro && countMembro.length > 0 && (
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="align-self-center">
                        <i className="icon-pencil primary font-large-2 float-left"></i>
                      </div>
                      <div className="media-body text-right">
                        <h3>{countMembro[0].membros}</h3>
                        <span>Membros</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="media d-flex">
                    <div className="align-self-center">
                      <i className="icon-speech warning font-large-2 float-left"></i>
                    </div>
                    <div className="media-body text-right">
                      <h3>156</h3>
                      <span>Boletins de Voto</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* Votos Descarregados */}
          {votos && countVoto.length > 0 && (
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="align-self-center">
                        <i className="icon-graph success font-large-2 float-left"></i>
                      </div>
                      <div className="media-body text-right">
                        <h3>{countVoto[0].votos}</h3>
                        <span>Votos Descarregados</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Apagar */}
          <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="media d-flex">
                    <div className="media-body text-left">
                      <div>
                        <button
                          className="btn btn-outline-danger"
                          onClick={handleDelete}
                        >
                          Apagar
                        </button>
                      </div>
                      <span>Apagar Votos</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-xl-6 col-sm-6 col-12 m-lg-5">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="media d-flex">
                      <div className="media-body text-left">
                        <h3 className="primary">Versiculo do Mês</h3>
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control mb-4"
                        id="exampleFormControlTextarea1"
                        rows="6"
                        name="versiculo"
                        placeholder="Versiculo"
                        {...register("versiculo")}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <Input
                        className="form-control"
                        id="nome"
                        type="text"
                        name="livro"
                        register={register}
                        label="Livro"
                        placeholder="versiculo"
                      />
                    </div>
                    <button className="btn btn-outline-success">
                      Modificar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/* <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="media d-flex">
                    <div className="align-self-center">
                      <i className="icon-pointer danger font-large-2 float-left"></i>
                    </div>
                    <div className="media-body text-right">
                      <h3>423</h3>
                      <span>Total Visitantes</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="row">
          {/* <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="media d-flex">
                    <div className="media-body text-left">
                      <h3 className="danger">278</h3>
                      <span>Publicar</span>
                    </div>
                    <div className="align-self-center">
                      <i className="icon-rocket danger font-large-2 float-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="warning">Adiconar Versiculo Mês</h3>
                    <span>Conversion Rate</span>
                  </div>
                  <div className="align-self-center">
                    <i className="icon-pie-chart warning font-large-2 float-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="primary">423</h3>
                    <span>Support Tickets</span>
                  </div>
                  <div className="align-self-center">
                    <i className="icon-support primary font-large-2 float-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
}

export default Daschboard;
