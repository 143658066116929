import React, { useState, useEffect } from "react";
import MyNavbar from "./common/nav";
import { getSelectDepartamentos,  getVotos } from "./services/source";
import { Table } from "react-bootstrap";

function Resultados() {
  const [department, setDeparment] = useState([]);
  const [allVotos, setAllVotos] = useState([]);

  useEffect(() => {
    async function getListDepatment() {
      const result = await getSelectDepartamentos();
      setDeparment(result.data);
    }
    getListDepatment();
  }, []);

  useEffect(() => {
    async function getAllVotos() {
      const result = await getVotos();
      setAllVotos(result.data);
    }
    getAllVotos();
  }, []);

  const mappedObjects = department.map((firstItem) => {
    const matchingObjects = allVotos.filter(
      (secondItem) => secondItem.departamento === firstItem.departamento
    );
    return { ...firstItem, objects: matchingObjects };
  });

  return (
    <div className="content">
      <MyNavbar />
      <div className="text-center" style={{ color: "white" }}>
        <h1>Resultados</h1>
        <div className="container-fluid">
        <div className="mt-3">
          <div className="col-md-8 col-xl-6 mx-auto">
            {mappedObjects
            .map((v, index) => {
              return (
                <div className="card" key={index}>
                  <div className="card-header">
                    <h5
                      className="text-center mb-0"
                      style={{ fontWeigth: "bold" }}
                    >
                      {v.departamento}
                    </h5>
                  </div>
                  <div className="card-body">
                    {v.objects
                    .sort((a,b) => b.Count - a.Count)
                    .map((k, index) => {
                      return (
                        <div key={index}>
                          <Table>
                            <tbody>
                              <tr>
                                <td className="text-center">
                                  <img
                                    src={
                                      process.env.REACT_APP_API_URL +
                                      "/" +
                                      k.file
                                    }
                                    alt="Foto"
                                    width="70px"
                                    height="70px"
                                    className="avatar"
                                  />
                                </td>
                                <td className="text-center pt-3">
                                  <span className="names">{k.nome}</span>
                                </td>
                                <td className="text-center pt-3">
                                <span className="names">{k.Count}</span>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Resultados;
