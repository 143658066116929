import React from "react";
import { deleteBoletim } from "../services/source";

function DelBoletim(data) {
  const handleDelete = async () => {
    await deleteBoletim(data).then((res) =>
      res ? window.location.reload(true) : null
    );
  };

  return (
    <>
      <p>
        Tem a certeza que deseja apagar permanentemente o boletim de voto da
        <strong> {data.departamento}</strong>!!!!
      </p>

      <button className="btn btn-danger" type="submit" onClick={handleDelete}>
        Apagar
      </button>
    </>
  );
}

export default DelBoletim;
