import http from "./httpServices";

export function addMenbro(formData) {
  return http.post("/add_menbro.php", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getMembro() {
  return http.get("/get_membro.php");
}

export function editMembro(data) {
  return http.post("/edit_membro.php", data);
}

export function deleteMembro(data) {
  return http.post("/delete_membro.php", data);
}

export function getCandidatos() {
  return http.get("/get_candidatos.php");
}

export function createBoletimVoto(data) {
  return http.post("/create_boletim.php", data);
}

export function createBoletimMultiVoto(data) {
  return http.post("/create_multi_boletim.php", data);
}

export function getDepartamentos() {
  return http.get("/get_departamentos.php");
}

export function displayBoletim(data) {
  return http.post("/get_boletim.php", data);
}

export function deleteBoletim(data) {
  return http.post("/delete_boletim.php", data);
}

export function getCard() {
  return http.get("/get_card.php");
}

export function votos(data) {
  return http.post("/insert_voto.php", data);
}

export function getVotos() {
  return http.get("/get_votos.php");
}

export function setVoto(data) {
  return http.post("/set_voto.php", data);
}

export function getPublicar() {
  return http.get("/get_publicar.php");
}

export function setBoletim(data) {
  return http.post("/update_boletim.php", data);
}

export function getSelectDepartamentos() {
  return http.get("/get_select_department.php");
}

export function addManVotos(data) {
  return http.post("/add_man_votos.php", data);
}

export function getVersiculo() {
  return http.get("/get_versiculo.php");
}

export function updateVersiculo(data) {
  return http.post("/update_versiculo.php", data);
}

export function countMembros(){
    return http.get("/count_membros.php");
}

export function countVotos(){
    return http.get("/count_votos.php");
}

export function deleteVotos(){
    return http.delete("/delete_votos.php");
}